<template>
  <client-only>
    <div class="flex justify-between flex-col min-h-screen">
      <div class="grid w-full h-screen fixed z-50 bg-primary-600">
        <NuxtImg data-not-lazy :src="fileUrl('2bf9513d-778e-44a7-85de-aae7d13155bb')" alt="Vers van de Velde" class="object-scale-down h-16 justify-self-center"/>
        <h1 class="headtext max-w-7xl text-2.5xl text-white leading-8 justify-self-center text-center px-5">Vandaag zijn wij bezig met het ombouwen van de webshop, morgen 19 november zullen wij weer beschikbaar zijn met het nieuwe concept... <br/>Abonnementen.</h1>
      </div>
    </div>
  </client-only>
</template>

<script setup>
const { fileUrl } = await useFiles()
</script>